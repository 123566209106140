.project-section {
  width: 100%;
  min-width: 1200px;
  height: 500px;
  background-color: #e6e6e7;

  @include clearfix;
}
.project-section__left {
  position: relative;
  float: left;
  width: 50%;
  height: 100%;
  &:after {
    position: absolute;
    top: 25px;
    right: 25px;
    bottom: 25px;
    left: 0;
    margin: auto;
    content: '';
    pointer-events: none;
    border: 1px solid $brand;
    border-left: none;
  }
}
.project-section__left--img {
  overflow: hidden;
  height: 100%;
  img {
    display: block;
    float: right;
    height: 100%;
  }
}
.project-section__left--item {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 550px;
  height: 200px;
  margin: auto;
  text-align: center;
}
.project-section__left--title {
  font-size: 38px;
  line-height: 40px;
  margin: 30px 0;
  text-transform: uppercase;
  color: #fff;

  @include opensans(light);
}
.project-section__right {
  float: left;
  width: 50%;
  height: 500px;
  &:after {
    display: inline-block;
    height: 100%;
    content: '';
    vertical-align: middle;
  }
}
.project-section__right--article {
  display: inline-block;
  width: 600px;
  padding: 25px;
  vertical-align: middle;
}
.project-section__right--text {
  margin-bottom: 20px;
  p {
    font-size: 14px;
    margin: 10px 0;
    color: #3f3f3f;
  }
}

.project-section__right--item {
  position: relative;
  display: inline-block;
  width: 48%;
  text-align: center;
  vertical-align: top;
  &:hover {
    .project-section__right--title {
      color: $brand;
    }
    .project-section__right--icon {
      svg {
        transition: 0s;

        fill: $brand;
      }
    }
  }
}
.project-section__right--icon {
  margin: 20px 0;
  svg {
    width: 100px;
    height: 70px;
    margin: 0 auto;
    transition: 0s;
  }
}
.project-section__right--title {
  font-size: 14px;
  text-decoration: underline;
  text-transform: uppercase;
  color: #3f3f3f;

  @include opensans(regular);
}
.project-section__right--link {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
}

.jThumbnailScroller {
  width: 100%;
}