@mixin opensans($style : regular) {
    @if $style == regular {
        font-family: 'OpenSans-Regular', Arial, sans-serif;
    }

    @if $style == bold {
        font-family: 'OpenSans-Bold', Arial, sans-serif;
    }

    @if $style == light {
        font-family: 'OpenSans-Light', Arial, sans-serif;
    }

    @if $style == semibold {
        font-family: 'OpenSans-Semibold', Arial, sans-serif;
    }
}

@mixin clearfix {
    &::before,
    &::after {
        content: "";
        display: table;
    }
    &::after {
        clear: both;
    }
}

// ===== placeholder color =====
@mixin placecolor($color: #5f5f5f) {
    &::-webkit-input-placeholder {
        color: $color;
    }
    &:-moz-placeholder {
        color: $color;
    }
    &::-moz-placeholder {
        color: $color;
    }
    &:-ms-input-placeholder {
        color: $color;
    }
}


// ===== calculate to Rem =====
@function calculateRem($size) {
  $remSize: $size / 16px;
  @return $remSize * 1rem;
}

@mixin font-size($size) {
  font-size: $size;
  font-size: calculateRem($size);
}

@mixin line-height($size) {
  line-height: $size;
  line-height: calculateRem($size);
}