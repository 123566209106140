.container-catalog-2 {
  @include clearfix;
}
.catalog-2-aside {
  float: left;
  width: 205px;
  margin-top: -40px;
  padding: 20px;
  border: 1px solid #ababab;
  background-color: #fff;
}
.catalog-2-aside__article {
  margin-bottom: 20px;
}
.catalog-2-aside__title {
  font-size: 18px;
  line-height: 22px;
  position: relative;
  text-transform: uppercase;
  color: #3f3f3f;

  @include opensans(light);
  &:after {
    display: block;
    width: 100%;
    height: 1px;
    margin-top: 10px;
    content: '';
    background-color: $brand;
  }
}
.catalog-2-aside__list {
  margin: 10px 0;
  padding: 0;
}
.catalog-2-aside__item {
  position: relative;
  padding: 5px 0 5px 15px;
  list-style: none;
  &:hover {
    .catalog-2-aside__link {
      color: $brand;
    }
  }
  &.active {
    margin-right: -20px;
    background-color: $brand;
    &:after {
      position: absolute;
      top: 0;
      right: -15px;
      bottom: 0;
      width: 0;
      height: 0;
      content: '';
      border-width: 15px 0 15px 15px;
      border-style: solid;
      border-color: transparent transparent transparent $brand;
    }
    .catalog-2-aside__link {
      color: #fff;
    }
  }
}
.catalog-2-aside__link {
  font-size: 14px;
  text-decoration: none;
  color: #3f3f3f;

  @include opensans(regular);
}


.catalog-2-wrap {
  width: 927px;
  margin-left: 230px;
}

.catalog-2-header__title {
  font-size: 36px;
  margin-bottom: 20px;
  text-transform: uppercase;
  color: #3f3f3f;

  @include opensans(light);
}
.catalog-2-header__text {
  font-size: 14px;
  line-height: 18px;
  max-width: 850px;
  margin-bottom: 20px;
  color: #3f3f3f;

  @include opensans(regular);
}

.catalog-2__filter {
  padding: 15px;
  margin-top: -20px;
  .ui-selectmenu-button.ui-button {
    width: 100%;
    outline: none;
  }
  .ui-button .ui-icon {
    background: transparent url('../img/selectmenu-arr.png') 50% 50% no-repeat;
  }
  .ui-corner-all {
    border-radius: 0;
  }
  .ui-button {
    padding: 5px 5px 5px 10px;
  }
}
.ui-menu .ui-menu-item-wrapper {
  background: #f6f6f6;
}
.ui-button.ui-state-active:hover,
.ui-button:active,
.ui-state-active,
.ui-widget-content .ui-state-active,
.ui-widget-header .ui-state-active,
a.ui-button:active {
  border: 1px solid $brand;
  background: $brand;
}
.catalog-2__select--wrap {
  display: inline-block;
  width: 175px;
  padding: 10px;
}
.catalog-2__select--title {
  font-size: 16px;
  margin-bottom: 10px;
  color: #3f3f3f;
  text-transform: uppercase;

  @include opensans(bold);
}
.catalog-2__select {
  width: 100%;
}

.catalog-2__abc-filter {
  margin-bottom: 20px;
  padding: 5px 15px;
  border: 1px solid $brand;
}
.abc-filter__list {
  margin: 0;
  padding: 0;
  text-align: center;
}
.abc-filter__item {
  display: inline-block;
  list-style: none;
  &.active {
    .abc-filter__link {
      color: #fff;
      background-color: $brand;
    }
  }
}
.abc-filter__link {
  font-size: 14px;
  line-height: 27px;
  display: inline-block;
  width: 27px;
  text-decoration: none;
  color: #5e4234;
  &:hover {
    color: $brand;
  }
}

// #catalog-2__stone-sample
.stone-sample__list {
  margin: 0;
  padding: 0;
}
.stone-sample__item {
  position: relative;
  display: inline-block;
  width: 160px;
  min-height: 240px;
  margin: 0 28px 20px 0;
  list-style: none;
  transition: .5s;
  vertical-align: top;
  &:nth-child(5n) {
    margin-right: 0;
  }
  &:hover {
    transition: .5s;
    background-color: $brand;
    .stone-sample__price,
    .stone-sample__title {
      transition: .5s;
      color: #fff;
    }
    .stone-sample__pic {
      &::before {
        opacity: 1;
      }
      }
  }
}
.stone-sample__pic {
  position: relative;
  overflow: hidden;
  margin-bottom: 5px;
  &::before {
    transition: .5s;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: block;
    margin: auto;
    content: '';
    opacity: 0;
    background: rgba(0,0,0,.3);
  }
  img {
    display: block;
    width: 100%;
  }
}
.stone-sample__title {
  font-size: 14px;
  line-height: 18px;
  margin-bottom: 5px;
  margin-left: 10px;
  transition: .5s;
  text-transform: uppercase;
  color: #212026;

  @include opensans(regular);
}
.stone-sample__price {
  font-size: 14px;
  line-height: 18px;
  margin-left: 10px;
  transition: .5s;
  color: #212026;

  @include opensans(bold);
}
.stone-sample__link {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
}

// end #catalog-2__stone-sample
