.newsone__item {
  position: relative;
  display: inline-block;
  width: 100%;
  min-height: 200px;
  margin: 30px 20px;
  padding: 20px 60px 20px 170px;
  transition: .5s;
  border: 1px solid #dbdbdb;
  &:hover {
    transition: .5s;
    border: 1px solid $brand;
    .news-section__title {
      color: $brand;
    }
  }
}
.newsone__img--wrap {
  margin-left: -190px;
  margin-top: -40px;
  float: left;
  overflow: hidden;
  width: 170px;

  @include clearfix;
}
.newsone__img {
  overflow: hidden;
  width: 170px;
  height: 170px;
  margin-bottom: 15px;
  img {
    display: block;
    width: 100%;
  }
}
.newsone__title {
  font-size: 20px;
  transition: .5s;
  text-transform: uppercase;
  color: #484848;

  @include opensans(light);
}
.newsone__date {
  font-size: 14px;
  margin-bottom: 10px;
  color: #484848;

  @include opensans(bold);
}
.newsone__text p {
  font-size: 14px;
  line-height: 18px;
  margin: 10px 0;
  color: #484848;

  @include opensans(regular);
}
