#map {
  position: absolute;
  top: 195px;
  right: 0;
  bottom: 140px;
  left: 0;
  margin: auto;
}
.contact__list {
  width: 1100px;
  margin: 10px auto 0;
  border: 1px solid $brand;
  background-color: #fff;
}
.contact__item {
  display: inline-block;
  width: 200px;
  min-height: 90px;
  margin: 20px 0;
  padding: 5px 30px;
  vertical-align: top;
  border-left: 1px solid #053b6c;
  &:first-child {
    width: 470px;
    border-left: none;
  }
}
.contact__item--title {
  @include opensans(bold);
  font-size: 18px;
  color: #3f3f3f;
  margin-bottom: 10px;
}
.contact__item--link {
  color: #484848;
  text-decoration: none;
  font-size: 14px;
  line-height: 20px;
  &:hover {
    text-decoration: underline;
  }
}
