.contacts-item {
  padding: 0 30px;
  border-right: 1px solid #fff;
}
.contacts-item__title {
  font-size: 14px;
  margin-bottom: 5px;
  color: #fff;
}
.contacts-item__link {
  font-size: 14px;
  line-height: 16px;
  transition: .5s;
  text-decoration: none;
  color: #fff;

  @include opensans(regular);
  &:hover {
    transition: .5s;
    color: $brand;
  }
}
.top-section {
  width: 100%;
  min-width: 1200px;
}
.top-section__title {
  position: absolute;
  z-index: 10;
  top: 200px;
  right: 0;
  left: 0;
  display: block;
  width: 730px;
  min-width: 1200px;
  margin: auto;
  text-align: center;
}
.top-section__title-text {
  font-size: 30px;
  text-transform: uppercase;
  color: #fff;

  @include opensans(light);
}
#top-section-slider {
  position: relative;
  width: 100%;
  min-width: 1200px;
  //общие стили для верхнего слайдера
  ul {
    margin: 0;
    padding: 0;
  }
  li {
    padding: 0;
  }
  .bx-wrapper {
    margin: 0 auto;
    border: none;
    background-color: transparent;
    box-shadow: none;
    .bx-viewport {
      min-height: 580px;
    }
  }
  .bx-controls-direction {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    visibility: hidden;
    max-width: 600px;
    margin: auto;
  }
  .bx-wrapper .bx-controls-direction a {
    width: 70px;
    height: 60px;
    margin-top: 60px;
  }
  .bx-wrapper .bx-prev {
    visibility: visible;
    background: url('../img/top-slider-arr.png') 15px 11px no-repeat;
    &:after {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      display: block;
      margin: auto;
      content: '';
      transition: .5s;
      opacity: 0;
      background: url('../img/top-slider-arr.png') 15px -60px no-repeat;
    }
    &:hover {
      &:after {
        transition: .5s;
        opacity: 1;
      }
    }
  }
  .bx-wrapper .bx-next {
    visibility: visible;
    background: url('../img/top-slider-arr.png') -130px 12px no-repeat;
    &:after {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      display: block;
      margin: auto;
      content: '';
      transition: .5s;
      opacity: 0;
      background: url('../img/top-slider-arr.png') -130px -59px no-repeat;
    }
    &:hover {
      &:after {
        transition: .5s;
        opacity: 1;
      }
    }
  }
  .bx-wrapper .bx-pager {
    bottom: 30px;
  }
  .bx-wrapper .bx-pager.bx-default-pager a {
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background: #fff;
  }
  .bx-wrapper .bx-pager.bx-default-pager a.active {
    background: #ac6258;
  }
  .bx-wrapper .bx-pager.bx-default-pager a:focus,
  .bx-wrapper .bx-pager.bx-default-pager a:hover {
    background: $brand;
  }
}
.top-section-slider__item {
  position: relative;
  &:after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: block;
    margin: auto;
    content: '';
    background: transparent url('../img/top-slider-filter.png') 0 0/cover no-repeat;
  }
}
.top-section-slider__img {
  min-height: 580px;
}
.top-section-slider__banner {
  position: absolute;
  z-index: 10;
  right: 0;
  bottom: 100px;
  left: 0;
  width: 300px;
  margin: auto;
  text-align: center;
}
.top-section-slider__title {
  font-size: 18px;
  display: inline-block;
  margin-bottom: 20px;
  text-transform: uppercase;
  color: #fff;

  @include opensans(semibold);
}
.top-section-slider__brand {
  margin-bottom: 20px;
  img {
    max-width: 100%;
    margin: 0 auto;
  }
}
