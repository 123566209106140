.inner {
  h2 {
    font-size: 24px;
    margin-bottom: 10px;
    text-transform: uppercase;
    color: #484848;

    @include opensans(light);
  }
  h3 {
    font-size: 24px;
    margin-bottom: 10px;
    text-transform: uppercase;
    color: #3b3e41;

    @include opensans(bold);
  }
  h4 {
    font-size: 18px;
    margin-bottom: 10px;
    text-transform: uppercase;
    color: #ff5b5b;

    @include opensans(bold);
  }
  h5 {
    font-size: 18px;
    margin-bottom: 10px;
    color: #7a7a7a;

    @include opensans(bold);
  }
  h6 {
    font-size: 18px;
    margin-bottom: 10px;
    color: #ff5b5b;

    @include opensans(bold);
  }
  p {
    margin-bottom: 15px;
    display: inline-block;
    font-size: 14px;
    line-height: 18px;
    color: #484848;

    @include opensans(regular);
  }
  a {
    font-size: 14px;
    line-height: 18px;
    transition: .5s;
    text-decoration: none;
    color: #ff5b5b;
    border-bottom: 1px solid #ff5b5b;

    @include opensans(regular);
    &:hover {
      transition: .5s;
      border-bottom: 1px solid transparent;
    }
  }
  ul {
    li {
      position: relative;
      list-style: none;
      &::before {
        position: absolute;
        top: 0;
        bottom: 0;
        left: -15px;
        width: 8px;
        height: 8px;
        margin: auto;
        content: '';
        background-color: #ff5b5b;
      }
    }
  }
}
