.wrapper.err {
  height: 100%;
  background: #000 url('../img/bg-404.jpg') 0 0/cover no-repeat;
}
.err-inn {
  height: 100%;
  text-align: center;
  &:after {
    display: inline-block;
    height: 100%;
    content: '';
    vertical-align: middle;
  }
}
.err-banner {
  position: relative;
  display: inline-block;
  vertical-align: middle;
}
.err-banner__subtitle {
  font-size: 18px;
  position: absolute;
  top: 10px;
  left: -20px;
  color: #fff;

  @include opensans(semibold);
}
.err-banner__title {
  font-size: 120px;
  line-height: 1;
  margin-bottom: 50px;
  color: #fff;

  @include opensans(semibold);
}
.err-banner__text {
  font-size: 18px;
  margin-bottom: 30px;
  color: #fff;  @include opensans(semibold);
}
.err__btn {
  margin-bottom: 40px;
  text-align: center;
  .btn-wrap {
    display: inline-block;
    text-align: center;
  }
  .btn-link {
    max-width: 220px;
    padding: 0 30px;
    color: #fff;

    @include opensans(light);
    &:hover {
      color: $brand;
      rect {
        stroke-dasharray: 10, 320;
        stroke-dashoffset: 10;
      }
    }
  }
}
