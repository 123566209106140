.card-header {
  margin-bottom: 20px;
}
.card-header__title {
  font-size: 36px;
  text-transform: uppercase;
  color: #3f3f3f;

  @include opensans(light);
}
.card-header__rutitle {
  font-size: 18px;
  text-transform: uppercase;
  color: #3f3f3f;

  @include opensans(regular);
}


.card-slider {
  position: relative;
  display: inline-block;
  width: 430px;
  margin-bottom: 20px;
  // width: 410px;
  padding: 9px;
  vertical-align: top;
  border: 1px solid #e0e0e0;
  &::before {
    position: absolute;
    top: 0;
    right: -15px;
    bottom: 0;
    display: block;
    width: 1px;
    height: 100%;
    content: '';
    background-color: $brand;
  }
  ul {
    margin: 0;
    padding: 0;
  }
  li {
    padding: 0;
  }
  .bx-wrapper {
    margin: 0 auto;
    margin-bottom: 10px;
    border: none;
    background-color: transparent;
    box-shadow: none;
  }
  .card-slider__pager {
    margin-right: -10px;
    a {
      &.active img {
        border: 1px solid $brand;
      }
      img {
        width: 50px;
        margin: 0 10px 5px 0;
      }
    }
  }
}

.card-table {
  display: inline-block;
  width: 490px;
  padding: 0 0 20px 20px;
  vertical-align: top;
}
.card-table__title {
  font-size: 20px;
  margin-top: -5px;
  margin-bottom: 5px;
  text-transform: uppercase;
  color: #000;

  @include opensans(semibold);
}
.card-table__text {
  font-size: 14px;
  margin-bottom: 20px;
  color: #3f3f3f;

  @include opensans(regular);
}
.card-table__table {
  width: 450px;
  margin-bottom: 20px;
  border-collapse: collapse;
  tr {
    &:nth-child(2n) {
      background-color: #f4f4f4;
    }
  }
  td {
    padding: 10px 15px;
    &.product {
      font-size: 16px;
      //width: 230px;
      width: 190px;
      color: #3f3f3f;

      @include opensans(semibold);
    }
    &.usd {
      font-size: 16px;
      padding-right: 0;
      text-align: right;
      color: #3f3f3f;

      @include opensans(regular);
    }
    &.rub {
      font-size: 16px;
      padding-left: 0;
      color: #3f3f3f;

      @include opensans(regular);
    }
  }
}






.card__param {
  margin-bottom: 30px;
  padding: 5px 15px;
  border: 1px solid $brand;
}
.card__param--wrap {
  display: inline-block;
  width: 175px;
  padding: 10px;
}
.card__param--title {
  font-size: 16px;
  margin-bottom: 5px;
  text-transform: uppercase;
  color: #3f3f3f;

  @include opensans(bold);
}
.card__param--subtitle {
  font-size: 14px;
  color: #67626c;
}

.catalog-2__stone-sample--title {
  font-size: 20px;
  margin-bottom: 20px;
  text-transform: uppercase;
  color: #000;

  @include opensans(semibold);
}
