#snowflakeContainer {
  position: absolute;
  z-index: 99999;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: transparent;
  pointer-events: none;
}

.snowflake {
  font-family: Cambria, Georgia, serif;
  font-size: 14px;
  line-height: 24px;
  position: fixed;
  z-index: 1000;
  padding-left: 15px;
  user-select: none;
  color: #fff;
}
.snowflake:hover {
  cursor: default;
}
