.gallery-item__article {
  position: relative;
  width: 1100px;
  margin: 45px auto 90px;
  padding: 25px 30px 25px 440px;
  border: 1px solid $brand;
  &:hover {
    .gallery-item__title {
      transition: all .5s;
      color: $brand;
    }
  }
}
.gallery-item__img-wrap {
  position: absolute;
  top: -30px;
  bottom: -30px;
  left: -1px;
  overflow: hidden;
  width: 400px;
  margin: auto;
  // background-color: #ccc;
}
.gallery-item__img {
  display: block;
  // height: 100%;
  width: 100%;
}
.gallery-item__title {
  font-size: 24px;
  margin-bottom: 20px;
  transition: all .5s;
  text-transform: uppercase;
  color: #484848;

  @include opensans(light);
}
.gallery-item__text {
  font-size: 14px;
  color: #484848;

  @include opensans(regular);
}
.gallery-item__link {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
}
