.welcome-section__list {
  text-align: center;
}
.welcome-section__text {
  width: 900px;
  margin: 0 auto;
  text-align: center;
  p {
    color: #3f3f3f;
  }
}
.welcome-section__item {
  position: relative;
  display: inline-block;
  width: 220px;
  margin: 0 20px;
  padding: 40px 0 20px 0;
  text-align: center;
  vertical-align: top;
  &:before {
    position: absolute;
    z-index: 0;
    top: 70px;
    right: 0;
    left: 0;
    display: block;
    width: 180px;
    height: 90px;
    margin: auto;
    content: '';
    border: 1px solid $brand;
  }
  &:hover {
    .welcome-section__item--title {
      color: $brand;
    }
  }
}
.welcome-section__item--pic {
  position: relative;
  z-index: 10;
}
.welcome-section__item--title {
  font-size: 18px;
  line-height: 20px;
  display: inline-block;
  margin: 10px 0;
  text-transform: uppercase;
  color: #3f3f3f;

  @include opensans(regular);
}
.welcome-section__item--link {
  position: absolute;
  z-index: 100;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
}
