.btn-wrap {
  display: inline-block;
  text-align: center;
}
.btn-link {
  font-size: 14px;
  line-height: 45px;
  position: relative;
  display: block;
  width: 100%;
  max-width: 170px;
  padding: 0 40px;
  text-decoration: none;
  letter-spacing: .5px;
  text-transform: uppercase;
  color: #fff;

  @include opensans(bold);
  svg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 45px;
  }
  rect {
    transition-timing-function: ease;
    transition-duration: .6s;
    transition-property: all;

    fill: none;
    stroke: $brand;
    stroke-width: 2;
    stroke-dasharray: 422, 0;
  }
  &:hover {
    font-weight: 600;
    color: $brand;
    rect {
      transition: all 1.35s cubic-bezier(.19, 1, .22, 1);

      stroke-width: 4;
      stroke-dasharray: 15, 310;
      stroke-dashoffset: 32;
    }
  }
}
