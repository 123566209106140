body {
  margin: 0;
}

// base
@import 'base/fonts';
@import 'base/variables';
@import 'base/mixins';
@import 'base/base';

//common
@import 'common/logo-item';
@import 'common/btn-link';
@import 'common/navigation';
@import 'common/header';
@import 'common/footer';
@import 'common/snow';

//pages
@import 'pages/index-top-section';
@import 'pages/index-welcome-section';
@import 'pages/index-project-section';
@import 'pages/index-stone-slider';
@import 'pages/index-news-section';
@import 'pages/catalog1';
@import 'pages/catalog2';
@import 'pages/card';
@import 'pages/contact';
@import 'pages/gallery';
@import 'pages/gallery-inn';
@import 'pages/newslist';
@import 'pages/newsone';
@import 'pages/err';
@import 'pages/inner';