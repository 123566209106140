.top-header {
  position: absolute;
  z-index: 1;
  top: 0;
  right: 0;
  left: 0;
  min-width: 1200px;
  .logo-item,
  .contacts-item__list,
  .contacts-item,
  .navigation {
    display: inline-block;
    vertical-align: middle;
  }
  .navigation {
    margin-top: 11px;
    margin-left: -19px;
  }
  .contacts-item:last-child {
    border-right: none;
  }
}
