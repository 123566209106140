.page-header {
  position: relative;
  overflow: hidden;
  width: 100%;
  min-width: 1200px;
  //padding-top: 90px;
  padding-top: 115px;
  padding-bottom: 25px;
}
.page-header__img {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.page-header__title {
  font-size: 30px;
  position: relative;
  text-transform: uppercase;
  color: #fff;@include opensans(light);
}
.breadcrumbs__list {
  margin: 0;
  padding: 0;
  .breadcrumbs__item:last-child {
    .breadcrumbs__link {
      text-decoration: none;
      pointer-events: none;
    }
  }
}
.breadcrumbs__item {
  display: inline-block;
  vertical-align: middle;
  &::after {
    display: inline-block;
    width: 1px;
    height: 20px;
    margin: 0 5px 0 10px;
    content: '';
    vertical-align: middle;
    background-color: $brand;
  }
  &:last-child {
    &::after {
      display: none;
    }
  }
}
.breadcrumbs__link {
  color: #fff;
  &:hover {
    text-decoration: none;
  }
}

.catalog-1-header {
  margin-bottom: 50px;
}
.catalog-1-header__title {
  font-size: 24px;
  position: relative;
  margin-bottom: 20px;
  text-transform: uppercase;
  color: #3f3f3f;

  @include opensans(light);
  &:before {
    position: absolute;
    top: 0;
    bottom: 0;
    left: -10px;
    display: block;
    width: 33px;
    height: 33px;
    margin: auto;
    content: '';
    border: 1px solid $brand;
  }
}
.catalog-1-header__text {
  font-size: 14px;
  line-height: 18px;
  max-width: 900px;
  margin-bottom: 20px;
  color: #3f3f3f;

  @include opensans(regular);
}

.catalog-1__row {
  margin-bottom: 20px;
}
.catalog-1__right,
.catalog-1__left {
  display: inline-block;
  vertical-align: top;
}
.catalog-1__left {
  width: 220px;
}
.catalog-1__pic {
  position: relative;
  text-align: center;
  &::before {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 180px;
    height: 90px;
    margin: auto;
    content: '';
    border: 1px solid $brand;
  }
  img {
    position: relative;
  }
}
.catalog-1__right {
  width: 920px;
  padding: 0 20px;
}
.catalog-1__title {
  font-size: 24px;
  margin: 22px 0;
  text-transform: uppercase;
  color: #3f3f3f;

  @include opensans(regular);
}
.catalog-1__text {
  font-size: 14px;
  line-height: 18px;
  color: #3f3f3f;
}
.catalog-1__list {
  display: inline-block;
  width: 30%;
  margin-left: 20px;
  vertical-align: top;
}
.catalog-1__item {
  position: relative;
  list-style: none;
  &::before {
    position: absolute;
    top: 0;
    bottom: 0;
    left: -35px;
    display: inline-block;
    width: 24px;
    height: 24px;
    margin: auto;
    content: '';
    background: url(../img/ul-catalog-1.png);
  }
}
.catalog-1__link {
  line-height: 30px;
  color: #3f3f3f;
  &:hover {
    text-decoration: none;
    color: $brand;
  }
}
