.navigation__list {
  position: relative;
  clear: both;
  margin: 0;
  padding: 0;
  list-style: none;
}
.navigation__item {
  position: relative;
  float: left;
  &:hover {
    .navigation__link {
      color: $brand;
      background: rgba(0, 0, 0, .5);
    }
    .navigation__sublist {
      width: auto;
      min-width: 100%;
      height: auto;
    }
    .navigation__subitem {
      opacity: 1;
    }
    .navigation__sublink {
      line-height: 35px;
    }
  }
}
.navigation__link {
  font-size: 14px;
  line-height: 36px;
  display: block;
  padding: 5px 20px;
  transition: .5s;
  text-decoration: none;
  text-transform: uppercase;
  color: #fff;

  @include opensans(bold);
  &.active {
    position: relative;
    &:before {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      display: block;
      width: 30px;
      height: 30px;
      margin: auto;
      content: '';
      border: 1px solid $brand;
    }
  }
}

.navigation__sublist {
  position: absolute;
  top: 46px;
  left: 0;
  clear: both;
  overflow: hidden;
  height: 0;
  margin: 0;
  padding: 0;
  list-style: none;
}

.navigation__subitem {
  width: 100%;
  transition: .5s;
  opacity: 0;
}
.navigation__sublink {
  font-size: 14px;
  line-height: 35px;
  display: block;
  padding: 5px 20px;
  transition: .5s;
  text-decoration: none;
  text-transform: uppercase;
  color: #fff;
  background: rgba(0, 0, 0, .5);

  @include opensans(bold);
  &:hover {
    color: $brand;
  }
}
