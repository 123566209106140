.gallery-inn__item {
  display: inline-block;
  overflow: hidden;
  width: 270px;
  height: 140px;
  margin-right: 20px;
  margin-bottom: 20px;
  vertical-align: top;
  background-color: $brand;
  &:nth-child(4n) {
    margin-right: 0;
  }
  &:hover {
    .gallery-inn__img {
      transition: .5s;
      opacity: .70;
    }
  }
}
.gallery-inn__img {
  width: 100%;
  transition: .5s;
  opacity: 1;
}
.lb-number {
  display: none !important;
}
.lb-outerContainer {
  border-radius: 0;
}
.lb-container {
  padding: 0;
  &:after {
    position: absolute;
    top: -7px;
    right: -7px;
    bottom: -7px;
    left: -7px;
    margin: auto;
    content: '';
    border: 1px solid $brand;
  }
}
.lightbox .lb-image {
  border-radius: 0;
}
.lb-data .lb-details {
  width: 100%;
  padding-top: 20px;
  text-align: center;
}
.lb-data .lb-caption {
  font-size: 24px;
  text-transform: uppercase;

  @include opensans(light);
}
