// ===== pagination =====
.pagination {
  padding: 10px 0;
  text-align: center;
  background-color: #fff;
}
.pagination__list {
  display: inline-block;
  margin: 0;
  padding: 0;
  vertical-align: middle;
}
.pagination__item {
  display: inline-block;
  margin: 0 5px;
  transition: all .5s;
  &.active {
    .pagination__link {
      transition: all .5s;
      border: 2px solid $brand;
    }
  }
}
.pagination__link {
  display: inline-block;
  // width: 35px;
  padding: 3px 10px;
  transition: all .5s;
  text-align: center;
  text-decoration: none;
  color: #696969;
  border: 2px solid transparent;

  @include opensans(bold);
  @include font-size(16px);
  &:hover {
    transition: all .5s;
    color: $brand;
  }
}
// ===== /pagination =====
