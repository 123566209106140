.news-section__item {
  position: relative;
  display: inline-block;
  vertical-align: top;
  width: 530px;
  min-height: 200px;
  margin: 30px 20px;
  padding: 20px 20px 20px 170px;
  transition: .5s;
  border: 1px solid #dbdbdb;
  &:hover {
    transition: .5s;
    border: 1px solid $brand;
    .news-section__title {
      color: $brand;
    }
  }
}
.news-section__img {
  position: absolute;
  top: -20px;
  left: -20px;
  overflow: hidden;
  width: 170px;
  height: 170px;
  img {
    display: block;
    width: 100%;
  }
}
.news-section__title {
    transition: .5s;
  font-size: 20px;
  text-transform: uppercase;
  color: #484848;

  @include opensans(light);
}
.news-section__date {
  font-size: 14px;
  margin-bottom: 10px;
  color: #484848;

  @include opensans(bold);
}
.news-section__text p {
  font-size: 14px;
  line-height: 18px;
  color: #484848;

  @include opensans(regular);
}
.news-section__link {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
}


.news-section__btn {
  text-align: center;
  margin-bottom: 40px;
  .btn-wrap {
    display: inline-block;
    text-align: center;
  }
  .btn-link {
    color: $brand;
    max-width: 190px;
    &:hover {
      rect {
        stroke-dasharray: 10, 315;
        stroke-dashoffset: 10;
      }
    }
  }

}
