.footer {
  background: #1e1d1d url('../img/bg-footer.jpg') 0 0/cover no-repeat;
  width: 100%;
  min-width: 1200px;
  z-index: 1;
  .logo-item,
  .contacts-item__list {
    margin: 10px 0;
    display: inline-block;
    vertical-align: middle;
  }
  .contacts-item {
    display: inline-block;
    vertical-align: top;
  }
  .contacts-item:last-child {
    border-right: none;
  }
  .contacts-item:nth-child(2) {
    border-right-color: $brand;
  }
}
.footer__copyright {
  background-color: #1e1d1d;
}
.footer__copyright--text {
  font-size: 10px;
  color: #fff;
}
