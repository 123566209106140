.stone-slider {
  width: 100%;
  min-width: 1200px;
  .content {
    position: relative;
    float: left;
    &::after {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      display: block;
      margin: auto;
      content: '';
      transition: .2s;
      background: rgba(0,0,0,.5);
    }
    &:hover::after {
      top: 40px;
      right: 40px;
      bottom: 40px;
      left: 40px;
      transition: .2s;
    }
    &:hover {
      .content__link,
      .content__item--inn {
        transition: .2s;
        opacity: 1;
      }
    }
    img {
      display: block;
    }
  }
  .content__item {
    position: absolute;
    z-index: 10;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    text-align: center;
    &:before {
      display: inline-block;
      height: 100%;
      content: '';
      vertical-align: middle;
    }
    .content__title {
      font-size: 20px;
      text-transform: uppercase;
      color: #fff;

      @include opensans(regular);
    }
    .content__subtitle1 {
      font-size: 14px;
      text-transform: uppercase;
      color: $brand;

      @include opensans(semibold);
    }
    .content__subtitle2 {
      font-size: 12px;
      text-transform: uppercase;
      color: $brand;

      @include opensans(regular);
    }
    .content__link {
      position: absolute;
      top: 23px;
      right: 23px;
      bottom: 23px;
      left: 23px;
      margin: auto;
      transition: .2s;
      opacity: 0;
      border: 1px solid $brand;
    }
  }
  .content__item--inn {
    display: inline-block;
    transition: .2s;
    vertical-align: middle;
    opacity: 0;
  }
}
