@font-face {
  font-family: 'OpenSans-Regular';
  src: url('../fonts/OpenSans-Regular/OpenSans-Regular.eot');
  src: url('../fonts/OpenSans-Regular/OpenSans-Regular.woff') format('woff'),
  url('../fonts/OpenSans-Regular/OpenSans-Regular.ttf') format('truetype'),
  url('../fonts/OpenSans-Regular/OpenSans-Regular.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'OpenSans-Bold';
  src: url('../fonts/OpenSans-Bold/OpenSans-Bold.eot');
  src: url('../fonts/OpenSans-Bold/OpenSans-Bold.woff') format('woff'),
  url('../fonts/OpenSans-Bold/OpenSans-Bold.ttf') format('truetype'),
  url('../fonts/OpenSans-Bold/OpenSans-Bold.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'OpenSans-Light';
  src: url('../fonts/OpenSans-Light/OpenSans-Light.eot');
  src: url('../fonts/OpenSans-Light/OpenSans-Light.woff') format('woff'),
  url('../fonts/OpenSans-Light/OpenSans-Light.ttf') format('truetype'),
  url('../fonts/OpenSans-Light/OpenSans-Light.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'OpenSans-Semibold';
  src: url('../fonts/OpenSans-Semibold/OpenSans-Semibold.eot');
  src: url('../fonts/OpenSans-Semibold/OpenSans-Semibold.woff') format('woff'),
  url('../fonts/OpenSans-Semibold/OpenSans-Semibold.ttf') format('truetype'),
  url('../fonts/OpenSans-Semibold/OpenSans-Semibold.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}