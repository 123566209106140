*,
*::before,
*::after {
  box-sizing: inherit;
  // transition-timing-function: ease;
  // transition-duration: .6s;
  // transition-property: all;
}
html {
  font-size: 16px;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
}
body {
  font-size: 14px;
  line-height: 1.4;
  display: flex;
  flex-direction: column;
  width: 100%;
  min-width: 320px;
  height: 100%;
  color: #696969;

  @include opensans(regular);
}

.header,
.footer {
  flex: 0 0 auto;
}
h1,
h2,
h3,
h4,
h5,
h6,
p {
  font-weight: 400;
  margin: 0;
  padding: 0;
}


h1,
h2,
h3,
h4,
h5,
h6 {
  @include opensans(bold);
}
p {
  color: #818181;

  @include opensans(regular);
}

.wrapper {
  flex: 1 0 auto;
  min-width: 1200px;
}
.container {
  position: relative;
  width: 1200px;
  margin: 0 auto;
  padding: 10px 20px;
}
.container-inn {
  position: relative;
  width: 1200px;
  margin: 0 auto;
  padding: 20px;
}
.section-header {
  text-align: center;
}
.section-title {
  font-size: 24px;
  position: relative;
  display: inline-block;
  padding: 20px 0;
  vertical-align: middle;
  text-transform: uppercase;
  color: #3f3f3f;
  background-color: #fff;

  @include opensans(light);
  &::before,
  &::after {
    display: inline-block;
    width: 215px;
    height: 12px;
    content: '';
    vertical-align: middle;
  }
  &::before {
    margin-right: 20px;
    background: url('../img/section-title-left.png') 0 0 no-repeat;
  }
  &::after {
    margin-left: 20px;
    background: url('../img/section-title-right.png') 0 0 no-repeat;
  }
}